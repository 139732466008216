<template>
  <div class="pickr-wrapper">
    <div class="pickr-wrap">
      <template v-if="dateProps.start">
        <!-- <input type="text" class="dateSelector start" placeholder="날짜를 선택하세요." /> -->
        <flat-pickr class="dateSelector" v-model="date.start" @on-change="setStartDate" :placeholder="$t('txt.selectDate')" :config="startConfig" />
      </template>
    </div>
  </div>
</template>
<script>
// import flatpickr from "flatpickr";
import flatPickr from 'vue-flatpickr-component'
import { Korean } from 'flatpickr/dist/l10n/ko.js'
import 'flatpickr/dist/flatpickr.css'
import { addDays } from 'date-fns'
import { getDateStr } from '@/libs/utils'
import UiCheck from '@/components/ui/UiCheck.vue'

export default {
  name: 'DatePicker',
  components: {
    UiCheck,
    flatPickr
  },
  props: {
    dateProps: {
      type: Object,
      default: {
        start: true,
        end: true
      }
    },
    startDefault: {
      type: Date,
      default: new Date()
    },
    endDefault: {
      type: Date,
      default: new Date()
    },
    configs: {
      type: Object,
      default: {
        enableTime: false,
        dateFormat: 'Y-m-d'
      }
    },
    withToday: {
      type: Boolean,
      default: false
    },
    isOldYn: {
      type: Boolean,
      default: false
    },
    oldYn: {
      type: String,
      default: 'N'
    },
    option: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      datepickr: '',
      date: {},
      startConfig: {
        // wrap: true, // set wrap to true only when using 'input-group'
        // altInput: true,
        time_24hr: true,
        enableTime: false,
        dateFormat: 'Y-m-d',
        locale: Korean,
        defaultDate: this.startDefault
      },
      endConfig: {
        time_24hr: true,
        enableTime: false,
        dateFormat: 'Y-m-d',
        locale: Korean,
        defaultDate: this.endDefault
      },
      selectedRange: '',
      dateRange: [
        {
          name: 'today',
          text: this.$t('button.today'),
          value: 'today'
        },
        {
          name: 'thisWeek',
          text: this.$t('button.week'),
          value: 'thisW'
        },
        {
          name: 'prevWeek',
          text: this.$t('button.lastweek'),
          value: 'prevW'
        },
        {
          name: 'thisMonth',
          text: this.$t('button.month'),
          value: 'thisM'
        },
        {
          name: 'prevMonth',
          text: this.$t('button.lastmonth'),
          value: 'prevM'
        }
      ]
    }
  },
  created () {
    this.setConfigs()

    if (this.startDefault && this.endDefault) {
      const startDate = getDateStr(this.startDefault, 'yyyy-MM-dd')
      const endDate = getDateStr(this.endDefault, 'yyyy-MM-dd')
      if (startDate === endDate) {
        this.dateRange[0].checked = true
      }
    }
  },
  watch: {
    withToday (value) {
      if (value) {
        this.date.end = new Date()
      } else {
        const yesterday = addDays(new Date(), -1)
        this.date.end = yesterday
      }
      this.date.end.setHours(23, 59, 59)
    }
  },
  methods: {
    setOldYn (value) {
      this.$emit('setOldYn', value)
    },
    getThisWeekFirstDay (date) {
      const paramDate = date // new Date('2021-06-08'): 화요일

      const day = paramDate.getDay()
      const diff = paramDate.getDate() - day + (day === 0 ? -6 : 1)
      return new Date(paramDate.setDate(diff)).toISOString().substring(0, 10)
    },
    maxDate () {
      const today = new Date()
      today.setHours(24, 0, 0)
      return today
    },
    setStartDate (date) {
      this.$emit('setStartDate', date)
    },
    setEndDate (date) {
      this.$emit('setEndDate', date)
    },
    setConfigs () {
      if (this.configs) {
        this.startConfig.enableTime = this.configs.enableTime
        this.startConfig.dateFormat = this.configs.dateFormat
        this.endConfig.enableTime = this.configs.enableTime
        this.endConfig.dateFormat = this.configs.dateFormat
        if (this.configs.maxDate) {
          this.startConfig.maxDate = this.configs.maxDate
          this.endConfig.maxDate = this.configs.maxDate
        }
      }
    },
    setDefalutDate () {
      if (!this.propDate) {
        this.date.start = new Date()
        this.date.start.setHours(0, 0, 0)
        this.date.end = new Date()
        this.date.end.setHours(23, 59, 59)
      }
    },
    inputBtnDate (e) {
      const target = e.target
      const value = target.value

      const today = new Date()

      if (value === 'today') {
        const targetDate = new Date()
        targetDate.setHours(0, 0, 0)
        this.date.start = targetDate

        today.setHours(23, 59, 59)
        this.date.end = today
      }

      if (value === 'thisW') {
        const targetDate = new Date(this.getThisWeekFirstDay(new Date()))
        targetDate.setHours(0, 0, 0)
        this.date.start = targetDate

        today.setHours(23, 59, 59)
        this.date.end = today
      } else if (value === 'prevW') {
        const prevDate = new Date().getDate() - 7
        today.setDate(prevDate)

        const targetDate = new Date(this.getThisWeekFirstDay(today))
        targetDate.setHours(0, 0, 0)
        this.date.start = targetDate

        const targetDateByEndDate = new Date(this.getThisWeekFirstDay(today))
        const endDate = targetDateByEndDate
        endDate.setDate(targetDate.getDate() + 6)
        endDate.setHours(23, 59, 59)
        this.date.end = endDate
      } else if (value === 'thisM') {
        const targetDate = new Date()
        targetDate.setDate(1)
        targetDate.setHours(0, 0, 0)
        this.date.start = targetDate

        today.setHours(23, 59, 59)
        this.date.end = today
      } else if (value === 'prevM') {
        const prevMonth = today.getMonth() - 1
        const prevMonthStart = new Date()
        prevMonthStart.setMonth(prevMonth)
        prevMonthStart.setDate(1)
        prevMonthStart.setHours(0, 0, 0)

        this.date.start = prevMonthStart

        const targetDate = new Date()
        targetDate.setDate(1)
        targetDate.setHours(0, 0, 0)
        const prevMonthEnd = new Date()
        prevMonthEnd.setDate(targetDate.getDate() - 1)
        prevMonthEnd.setHours(23, 59, 59)
        this.date.end = prevMonthEnd

        /* const prevMonthEnd = new Date()
        prevMonthEnd.setDate()

        const endDate = new Date()
        endDate.setDate(targetDate.getDate() + 6)
        endDate.setHours(23, 59, 59) */
      }
    }
  }
}
</script>

<style scoped>
i {border-radius: 5px;}
.pickr-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pickr-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pickr-wrap > span {
  font-weight: 800;
  font-size: 19px;
  margin: 0 5px;
}

.date-range-selector-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.range-selector-wrap {
  display: flex;
}
.range-selector-wrap > input {
  display: none;
}
.range-selector-wrap > input + label {
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #808080;
  background-image: linear-gradient(to bottom, #fff 19%, #f6f3e9);
  padding: 4px 8px;
  color: #252930;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  margin-right: 5px;
  font-size: 12px;
}
.range-selector-wrap > input:checked + label {
  background: #f6f3e9;
}
.range-selector-wrap:last-child > input + label {
  margin-right: 0;
}
</style>
